/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: GetCurrentUserDetailsInput) {
    CreateUser(input: $input)
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: GetCurrentUserDetailsInput) {
    DeleteUser(input: $input)
  }
`;
export const activateOrDeactivateUser = /* GraphQL */ `
  mutation ActivateOrDeactivateUser($input: ActivateOrDeactivateUserInput) {
    ActivateOrDeactivateUser(input: $input)
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput) {
    CreateOrganization(input: $input)
  }
`;
export const activateOrDeactivateOrganization = /* GraphQL */ `
  mutation ActivateOrDeactivateOrganization(
    $input: ActivateOrDeactivateOrganizationInput
  ) {
    ActivateOrDeactivateOrganization(input: $input)
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput) {
    UpdateOrganization(input: $input)
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($input: CreateAssetInput) {
    CreateAsset(input: $input)
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($input: UpdateAssetInput) {
    UpdateAsset(input: $input)
  }
`;
export const activateOrDeactivateAsset = /* GraphQL */ `
  mutation ActivateOrDeactivateAsset($input: ActivateOrDeactivateAssetInput) {
    ActivateOrDeactivateAsset(input: $input)
  }
`;
export const createPlantType = /* GraphQL */ `
  mutation CreatePlantType($input: CreatePlantTypeInput) {
    CreatePlantType(input: $input)
  }
`;
export const updatePlantType = /* GraphQL */ `
  mutation UpdatePlantType($input: CreatePlantTypeInput) {
    UpdatePlantType(input: $input)
  }
`;
export const activateOrDeactivatePlantType = /* GraphQL */ `
  mutation ActivateOrDeactivatePlantType(
    $input: ActivateOrDeactivatePlantTypeInput
  ) {
    ActivateOrDeactivatePlantType(input: $input)
  }
`;
export const createMasterParameter = /* GraphQL */ `
  mutation CreateMasterParameter($input: CreateMasterParameterInput) {
    CreateMasterParameter(input: $input)
  }
`;
export const createDivision = /* GraphQL */ `
  mutation CreateDivision($input: CreateDivisionInput) {
    CreateDivision(input: $input)
  }
`;
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision($input: UpdateDivisionInput) {
    UpdateDivision(input: $input)
  }
`;
export const activateOrDeactivateDivision = /* GraphQL */ `
  mutation ActivateOrDeactivateDivision(
    $input: ActivateOrDeactivateDivisionInput
  ) {
    ActivateOrDeactivateDivision(input: $input)
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput) {
    CreateDevice(input: $input)
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput) {
    UpdateDevice(input: $input)
  }
`;
export const activateOrDeactivateDevice = /* GraphQL */ `
  mutation ActivateOrDeactivateDevice($input: ActivateOrDeactivateDeviceInput) {
    ActivateOrDeactivateDevice(input: $input)
  }
`;
export const addTagToOrganization = /* GraphQL */ `
  mutation AddTagToOrganization($input: AddTagToOrganizationInput) {
    AddTagToOrganization(input: $input)
  }
`;
export const removeTagFromOrganization = /* GraphQL */ `
  mutation RemoveTagFromOrganization($input: RemoveTagFromOrganizationInput) {
    RemoveTagFromOrganization(input: $input)
  }
`;
export const editOrConfigureAlarmOfPlant = /* GraphQL */ `
  mutation EditOrConfigureAlarmOfPlant(
    $input: EditOrConfigureAlarmOfPlantInput
  ) {
    EditOrConfigureAlarmOfPlant(input: $input)
  }
`;
export const createPlantOfAsset = /* GraphQL */ `
  mutation CreatePlantOfAsset($input: CreatePlantOfAssetInput) {
    CreatePlantOfAsset(input: $input)
  }
`;
export const editTagOfOrganizationDashboard = /* GraphQL */ `
  mutation EditTagOfOrganizationDashboard(
    $input: EditTagOfOrganizationDashboardInput
  ) {
    EditTagOfOrganizationDashboard(input: $input)
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput) {
    DeleteOrganization(input: $input)
  }
`;
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision($input: DeleteDivisionInput) {
    DeleteDivision(input: $input)
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteDeviceInput) {
    DeleteDevice(input: $input)
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($input: DeleteAssetInput) {
    DeleteAsset(input: $input)
  }
`;
export const removeMasterParameter = /* GraphQL */ `
  mutation RemoveMasterParameter($input: RemoveMasterParameterInput) {
    RemoveMasterParameter(input: $input)
  }
`;
export const deletePlantType = /* GraphQL */ `
  mutation DeletePlantType($input: DeletePlantTypeInput) {
    DeletePlantType(input: $input)
  }
`;
export const updateMasterParameter = /* GraphQL */ `
  mutation UpdateMasterParameter($input: UpdateMasterParameterInput) {
    UpdateMasterParameter(input: $input)
  }
`;
