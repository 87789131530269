var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('CreatePlantTypeDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogcreatePlantType":_vm.dialogcreatePlantType},on:{"clicked":_vm.CreatePlantTypeDialogEmit}}),_c('ActivateInactivatePlantTypeDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogActivateInactivatePlantType":_vm.dialogActivateInactivatePlantType},on:{"clicked":_vm.dialogActivateInactivatePlantTypeEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Plant Types")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"field_height field_label_size FontSize mt-8 mr-2 maxWidthLarge",attrs:{"dense":"","outlined":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"borderRadius",attrs:{"small":"","color":"primary"},on:{"click":function($event){(_vm.dialogcreatePlantType = true), (_vm.StoreObj.action = 'CREATE')}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Create")],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.UFROtypeHeader,"items":_vm.GetAllUFROTypesList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.plant_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.plant_name)+" ")])]}},{key:"item.plant_created_on",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.plant_created_on ? new Date(item.plant_created_on).toLocaleString() : "-")+" ")])]}},{key:"item.plant_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize",class:item.plant_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'},[_vm._v(" "+_vm._s(item.plant_status)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":'green'},on:{"click":function($event){$event.stopPropagation();return _vm.checkItem(item, 'EDIT')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(("Edit " + (item.plant_name)))}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":'red'},on:{"click":function($event){$event.stopPropagation();return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(("Delete " + (item.plant_name)))}})])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }