import { listPlantTypes } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllPlantTypes = {
  data() {
    return {
      overlay: false,
      GetAllUFROTypesList: [],
    };
  },
  methods: {
    async GetAllPlantTypesMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listPlantTypes, {
            input: {},
          })
        );
        this.GetAllUFROTypesList = JSON.parse(result.data.ListPlantTypes).data.items;
        console.log("GetAllUFROTypesList", this.GetAllUFROTypesList);
        this.overlay = false;
      } catch (error) {
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
