<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreatePlantTypeDialog
      :StoreObj="StoreObj"
      :dialogcreatePlantType="dialogcreatePlantType"
      @clicked="CreatePlantTypeDialogEmit"
    />
    <ActivateInactivatePlantTypeDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivatePlantType="dialogActivateInactivatePlantType"
      @clicked="dialogActivateInactivatePlantTypeEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Plant Types</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              class="field_height field_label_size FontSize mt-8 mr-2 maxWidthLarge"
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-btn
              small
              color="primary"
              @click="(dialogcreatePlantType = true), (StoreObj.action = 'CREATE')"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :headers="UFROtypeHeader" :items="GetAllUFROTypesList" :search="search">
          <template v-slot:[`item.plant_name`]="{ item }">
            <div class="FontSize">
              {{ item.plant_name }}
            </div>
          </template>
          <template v-slot:[`item.plant_created_on`]="{ item }">
            <div class="FontSize">
              {{ item.plant_created_on ? new Date(item.plant_created_on).toLocaleString() : "-" }}
            </div>
          </template>
          <template v-slot:[`item.plant_status`]="{ item }">
            <div class="FontSize" :class="item.plant_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
              {{ item.plant_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkItem(item, 'EDIT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.plant_name}`"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkItem(item, 'ACTION')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Delete ${item.plant_name}`"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllPlantTypes } from "@/mixins/GetAllPlantTypes.js";
import CreatePlantTypeDialog from "@/components/PlantTypes/Dialogs/CreatePlantTypeDialog.vue";
import ActivateInactivatePlantTypeDialog from "@/components/PlantTypes/Dialogs/ActivateInactivatePlantTypeDialog.vue";
export default {
  components: {
    Overlay,
    CreatePlantTypeDialog,
    ActivateInactivatePlantTypeDialog,
  },
  mixins: [GetAllPlantTypes],
  data: () => ({
    search: "",
    StoreObj: {},
    dialogcreatePlantType: false,
    dialogActivateInactivatePlantType: false,
    UFROtypeHeader: [
      { text: "Name", value: "plant_name" },
      { text: "Created On", value: "plant_created_on" },
      // { text: "Time Zone", value: "time_zone" },
      // { text: "Status", value: "plant_status" },
      { text: "Action", value: "Actions" },
    ],
  }),
  mounted() {
    this.GetAllPlantTypesMethod();
  },
  methods: {
    checkItem(item, Action) {
      this.StoreObj = item;
      this.StoreObj.action = Action;
      switch (Action) {
        case "ACTION":
          this.dialogActivateInactivatePlantType = true;
          break;
        case "EDIT":
          console.log("item", item);
          this.dialogcreatePlantType = true;
          break;
      }
    },
    dialogActivateInactivatePlantTypeEmit(Toggle) {
      this.dialogActivateInactivatePlantType = false;
      if (Toggle == 2) {
        this.GetAllPlantTypesMethod();
      }
    },
    CreatePlantTypeDialogEmit(Toggle) {
      this.dialogcreatePlantType = false;
      if (Toggle == 2) {
        this.GetAllPlantTypesMethod();
      }
    },
  },
};
</script>
