<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="750px" v-model="dialogcreatePlantType">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create Plant</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createPlantTypeDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4" v-model="form">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-text-field
                  :readonly="StoreObj.action == 'EDIT'"
                  dense
                  outlined
                  label="Name"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_plant.plant_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="pl-0">
                <v-text-field
                  dense
                  outlined
                  label="Plant Tag"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_plant.plant_tag_id"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <!-- <v-row>
            <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
              <v-text-field
                dense
                outlined
                label="Rated Capacity"
                class="field_height field_label_size FontSize"
                :rules="[
                  (v) => !!v || 'Required',
                  (v) => /^(?=[a-z A-Z0-9@#._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Numeric only',
                ]"
                v-model="create_plant.rated_capacity"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
              <v-select
                dense
                outlined
                label="Rated Hrs of Operation"
                class="field_height field_label_size FontSize"
                :rules="[(v) => !!v || 'Required']"
                :items="ListOperationHours"
                v-model="create_plant.rated_hrs_of_operation"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
              <v-select
                dense
                outlined
                label="Time Zone"
                class="field_height field_label_size FontSize"
                :rules="[(v) => !!v || 'Required']"
                :items="ListTimeZone"
                item-text="text"
                item-value="value"
                v-model="create_plant.time_zone"
              ></v-select>
            </v-col>
          </v-row> -->
          <div class="font-weight-bold">Steps Of Operation :</div>
          <v-form ref="formAddStep" v-model="formAddStep" class="mt-9">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="4" class="py-0 mt-n5">
                <v-text-field
                  dense
                  outlined
                  label="Step Name"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="add_step.step_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="py-0 mt-n5">
                <v-text-field
                  dense
                  outlined
                  label="Step Number"
                  class="field_height field_label_size FontSize"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => /^(?=[a-z A-Z0-9@#._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Numeric only',
                  ]"
                  v-model="add_step.step_tag_value"
                ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" class="py-0 mt-n5">
                <v-btn small color="primary" :disabled="!formAddStep" @click="addStep"
                  ><v-icon>mdi-plus</v-icon>Add</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            dense
            class="elevation-0"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :headers="SOPHeaders"
            :items="plant_steps"
            :items-per-page="50"
            hide-default-footer
          >
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="FontSize">
                {{ plant_steps.indexOf(item) + 1 }}
              </div>
            </template>
            <template v-slot:[`item.step_name`]="{ item }">
              <div class="FontSize">
                {{ item.step_name }}
              </div>
            </template>
            <template v-slot:[`item.step_tag_value`]="{ item }">
              <div class="FontSize">
                {{ item.step_tag_value }}
              </div>
            </template>
            <template v-slot:[`item.rated_capacity`]="{ item }">
              <div class="FontSize">
                {{ item.rated_capacity }}
              </div>
            </template>
            <template v-slot:[`item.rated_hrs_of_operation`]="{ item }">
              <div class="FontSize">
                {{ item.rated_hrs_of_operation }}
              </div>
            </template>
            <template v-slot:[`item.time_zone`]="{ item }">
              <div class="FontSize">
                {{ item.time_zone }}
              </div>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-btn icon small color="red" @click="plant_steps.splice(plant_steps.indexOf(item), 1)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createPlantTypeDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            :disabled="plant_steps.length == 0 || !form"
            small
            class="mr-2 borderRadius"
            color="primary"
            :loading="loading"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createPlantType, updatePlantType } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogcreatePlantType: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
    create_plant: {
      plant_name: "",
      plant_tag_id: "",
    },
    parameterTypeItems: ["Operational", "Summary"],
    SOPHeaders: [
      {
        text: "Sl No.",
        value: "sl_no",
      },
      {
        text: "Step Name",
        value: "step_name",
      },
      {
        text: "Step Number",
        value: "step_tag_value",
      },
      {
        text: "Action",
        value: "Action",
      },
    ],
    add_step: {
      step_name: "",
      step_tag_value: "",
    },
    ListTimeZone: [
      {
        text: "Asia / Kolkata",
        value: "Asia/Kolkata",
      },
    ],
    ListOperationHours: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "2",
        value: 2,
      },
      {
        text: "3",
        value: 3,
      },
      {
        text: "4",
        value: 4,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "6",
        value: 6,
      },
      {
        text: "7",
        value: 7,
      },
      {
        text: "8",
        value: 8,
      },
      {
        text: "9",
        value: 9,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "11",
        value: 11,
      },
      {
        text: "12",
        value: 12,
      },
      {
        text: "13",
        value: 13,
      },
      {
        text: "14",
        value: 14,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "16",
        value: 16,
      },
      {
        text: "17",
        value: 17,
      },
      {
        text: "18",
        value: 18,
      },
      {
        text: "19",
        value: 19,
      },
      {
        text: "20",
        value: 20,
      },
    ],
    form: false,
    formAddStep: false,
    plant_steps: [],
  }),
  watch: {
    dialogcreatePlantType(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.create_plant = {
            plant_name: this.StoreObj.plant_name,
            plant_tag_id: this.StoreObj.plant_tag_id,
          };
          this.plant_steps = this.StoreObj.plant_steps;
        }
      }
    },
  },
  methods: {
    addStep() {
      // let add_step = { ...this.add_step };
      // add_step.rated_capacity = Number(add_step.rated_capacity);
      this.plant_steps.push({ ...this.add_step });
      this.$refs.formAddStep.reset();
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createPlantTypeMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async createPlantTypeMethod() {
      console.log("check Action", this.StoreObj.action);
      var mutationname = null;
      var mutationresult = null;
      var inputParams = {
        plant_tag_id: this.create_plant.plant_tag_id,
        plant_steps: this.plant_steps,
        plant_name: this.create_plant.plant_name,
        // rated_capacity: parseInt(this.create_plant.rated_capacity),
        // rated_hours_of_operation: this.create_plant.rated_hrs_of_operation,
        // time_zone: this.create_plant.time_zone,
      };
      switch (this.StoreObj.action) {
        case "CREATE":
          mutationname = createPlantType;
          mutationresult = "CreatePlantType";
          break;
        case "EDIT":
          console.log("Action", this.StoreObj.action);
          mutationname = updatePlantType;
          mutationresult = "UpdatePlantType";
          break;
      }

      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createPlantTypeDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createPlantTypeDialogEmit(Toggle) {
      this.create_plant = {
        plant_name: "",
        plant_tag_id: "",
      };
      this.plant_steps = [];
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
